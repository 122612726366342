import type { LocationQuery } from 'vue-router'
import type { RvSearchSorts } from '~/types/api'
import type { ISearchQuery } from '~/types/query/search'

export function extractSearchQuery(query: LocationQuery) {
  const all = Object.keys(query).reduce((acc: Record<string, string | undefined>, k) => {
    acc[k.toLowerCase()] = query[k]?.toString()
    return acc
  }, {})

  return {
    SearchAddress: all.searchaddress,

    SortOrder: all.sortorder ? (all.sortorder as RvSearchSorts) : undefined,

    Types: all.types?.split(','),
    RvBrand: all.rvbrand,

    MinLength: all.minlength ? Number(all.minlength) : undefined,
    MaxLength: all.maxlength ? Number(all.maxlength) : undefined,

    MinPrice: all.minprice ? Number(all.minprice) : undefined,
    MaxPrice: all.maxprice ? Number(all.maxprice) : undefined,

    MinRvYear: all.minrvyear ? Number(all.minrvyear) : undefined,
    MaxRvYear: all.maxrvyear ? Number(all.maxrvyear) : undefined,

    MinWeight: all.minweight ? Number(all.minweight) : undefined,
    MaxWeight: all.maxweight ? Number(all.maxweight) : undefined,

    NeLat: all.nelat ? Number(all.nelat) : undefined,
    NeLng: all.nelng ? Number(all.nelng) : undefined,
    SwLat: all.swlat ? Number(all.swlat) : undefined,
    SwLng: all.swlng ? Number(all.swlng) : undefined,
    ListingConditions: all.listingconditions?.split(',')
  } as ISearchQuery
}
